.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.pointer {
  cursor: pointer;
}

.noLinkView {
  text-decoration: none;
  color: black;
}

.view-report-button {
  width: 150px;
  background-color: #a8d0e6;
  font-size: 9px;
  padding: 10px 45px;
  border-radius: 12px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body-UniPage {
  padding-top: 20px;
  background-color: #FFFFFF;
}

.blue-link {
  color: #a8d0e6;
}

.profile-container {
  color: #FFFFFF;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.university-name {
  padding-left: 2.5rem;
  padding-bottom: 2.5rem;
  color: #24305E;
}

.flex-container-profile {
  display: flex;
  justify-content: left;
  padding-left: 4rem;
  padding-right: 4rem;
  color: #24305E;
}

.school-profile {
  flex: 1;
  text-align: left;
  padding-left: 3rem;
}

.greek-overview {
  flex: 2;
  text-align: left;
  padding-right: 3rem;
}

/* styling for home page */
.page-header {
  background-color: #FFFFFF;
  color: #24305E;
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: center;
}

.header-wrapper {
  border: 3px solid #24305E;
  border-radius: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
}
/* adjust button color */
.btn-primary {
  background-color: #24305E !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: #374785 !important;
}

.btn-secondary {
  background-color: #494a4b !important;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited,
.btn-secondary:focus {
  background-color: #374785 !important;
}

/* used this on other divs to add padding */
.button-wrapper {
  padding: 1rem;
}

/* incident page */
.table-wrapper {
  padding: 4rem;
}
.incident-paragraph {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 4rem;
}

/* styling for navigation links */
.navigation {
  background-color: #24305E;
  color: #FFFF;
}

.navigation p {
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}

/* styling for navbar */
 .nav-button {
  background-color: #24305E;
  color: #FFFFFF;
  border: solid 1.2px #FFFFFF;
  border-radius: 10px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
 }

 .nav-container {
  display: flex;
  padding: 1rem;
  justify-content: right;
 }

 .nav-button-container {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
 }

/* styling for incident info page  */

.incident-report-body {
  padding-top: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.incident-report-body h4 {
  color:#24305E;
}
.edit-incident-container {
  padding-top: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4rem;  
}

.edit-incident-container h3 {
  font-weight: 500;
  color: #24305E;
  padding-top: 1rem;
}
/* styling for new incident info and school profile submission page  */

#new-incident, #new-school {
  padding-top: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

#new-incident h3, #new-school h3 {
  font-weight: 500;
  color:#24305E;
  padding-bottom: 1rem;

}

.section-container {
  display: flex;
}
.section-container {
  display: flex;
}

.form-row {
  margin-right: 20px;
}

/* sets last input field to the same width as the others */
.equal-width label, .equal-width input {
  width: auto;
}

.button-wrapper-submit {
  padding-bottom: 4rem;
}

/* Logout Drop Down CSS */
/* Dropdown Button */
.dropbtn {
  background-color: #24305E;
  color: white;
  padding: 16px;
  font-size: 16px;
  border-color: white;
  border-radius: 10px;
  font-size: small;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #24305E;
}

@media screen and (max-width: 700px) {
  .section-container {
    display: flex;
    flex-direction: column;
  }
}

#question-button {
  background-color: #b4b4b4;
  height: 15px;
  width: 15px;
  font-size: 10px;
  color: white;
  border: none;
  border-radius: 100%;
  padding: 0px;
  text-align: center;
}
